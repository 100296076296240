import React, { useEffect, useState, useRef, useCallback } from 'react';
import './stores.css'
import { Button,Modal,ModalHeader} from 'reactstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
    Autocomplete,
    useLoadScript
} from '@react-google-maps/api';

export default function Stores() {
    const [origin, setOrigin] = useState('Naivas Prestige');
    const [destination, setDestination] = useState('');
    const [response, setResponse] = useState(null);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false)
    const [storeid,setStoreid] = useState('');
    const [storename, setStorename] = useState('');
    const [password, setPassword] = useState('');
    const [stores,setStores] = useState([]);
    const [selectedStore, setSelectedstore] = useState('')
    const [storeLocation, setStorelocation] = useState('')
    const [confirmPassword, setConfirmpassword] = useState('')

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCJQJxhVpVWG6dS-0mUlgkJ4lAEW-00y5Q', // Replace with your actual API key
        libraries: ['places'],
    });

    const directionsCallback = useCallback((result, status) => {
        if (status === 'OK' && result) {
            setResponse(result);
            const route = result.routes[0];
            const distanceText = route.legs[0].distance.text;
            const distanceValue = parseFloat(distanceText.replace('km', '').trim());

        }
    }, []);

    const handleFindRoute = useCallback(() => {
        if (origin && destination) {
            const DirectionsServiceOptions = {
                destination: destination,
                origin: origin,
                travelMode: 'DRIVING'
            };

            return <DirectionsService options={DirectionsServiceOptions} callback={directionsCallback} />;
        }
        return null;
    }, [origin, destination, directionsCallback]);

    const onPlaceSelected = (autocomplete) => {
        const place = autocomplete.getPlace();
        setStorelocation(place.formatted_address); // Set the selected place as the delivery location
        setDestination(place.formatted_address); // Update destination state with selected place
    };


    const toggle = ()=>{
        setModal(!modal)
    }
    const toggle2 = (e)=>{
        const id = e.target.value;
        setSelectedstore(id)
        setModal2(!modal2)

        Axios.get(`https://panel.yunshuglobal.com:3051/api/getThisstore?id=${id}`).then((res)=>{
            setStorename(res.data.storename)
            setStoreid(res.data.storeid);
            setPassword(res.data.storepass);

        })
    }
    
    const toggle3 =()=>{
        setModal3(!modal3)
    }

    useEffect(()=>{
        Axios.get('https://panel.yunshuglobal.com:3051/api/getAllstores').then((res)=>{
            setStores(res.data)
        })
    },[])

    const submitStore = () =>{
        if(storeid !== '' && storename !== '' &&  password !== '' && storeLocation !== '' && confirmPassword){
            if(password == confirmPassword){
            Axios.post('https://panel.yunshuglobal.com:3051/api/postStore',{
                storeid,storename,password,storeLocation
            }).then((res)=>{
                if(res.data.exist){
                    toast.error('Store id already exists!')
                }
                else if(res.data.success){
                    Axios.get('https://panel.yunshuglobal.com:3051/api/getAllstores').then((res)=>{
                        setStores(res.data)
                    })
                    setModal(!modal);
                    toast.success('Store has been added')
                }

            }).catch((err)=>{
                toast.error('Network connection error!')
            })
            }
            else{
                toast.error('Passwords do not match')
            }
        }
        else{
            toast.error('All fields are requred!')
        }
    }

    const editStore = ()=>{
        Axios.put('https://panel.yunshuglobal.com:3051/api/updateStore',{
            storeid,storename,password,selectedStore
        }).then((res)=>{
            if(res.data.success){
                Axios.get('https://panel.yunshuglobal.com:3051/api/getAllstores').then((res)=>{
                    setStores(res.data)
                })
                toast.success('Changes have been saved!');
                setModal2(false);

            }
            
            else{
                toast.error('Something went wrong. Please contact support!')
            }
        }).catch((err)=>{
            toast.error('Network connection error!')
        })
    }

    const deleteStore = () =>{
        Axios.delete(`https://panel.yunshuglobal.com:3051/api/deleteStore?id=${selectedStore}`).then((res)=>{
            if(res.data.success){
                Axios.get('https://panel.yunshuglobal.com:3051/api/getAllstores').then((res)=>{
                    setStores(res.data)
                })

                setModal2(false)
                setModal3(false)
                toast.success('Store has been deleted')
            }
            else{
                toast.error('Something went wrong! Please contact support')
            }
            
        }).catch((err)=>{
            toast.error('Network connection error')
        })
    }
    


  return (

    <div className='homeWrapper'>
    <Header />
<div className='homeContainer'>
    <div className="sidebar">
        <Sidebar />
    </div>
    <div className="maincontent">
    <div className='storesWrapper'>
        <ToastContainer />
        <h4 className='storesTitle'>Stores</h4>
        <hr />
        <div className="storesIntro">
            <Button onClick={toggle} color='success'>Add New Store</Button>
        </div>
        <div className="storesTable">
        <Table striped bordered hover sx={{ minWidth: 650 }} aria-label="simple table"  className="table">
        <thead>
          <tr>
            <th className='tHeading'>#</th>
            <th className='tHeading'>Store id</th>
            
            <th className='tHeading'>Store name</th>
            <th className='tHeading'>Number of orders</th>

          </tr>
        </thead>
        <tbody>
          {stores.map((val, index) => {
          
            return (
               
              <tr key={index} className='roow'>
               <td>{index+1}</td> 
               <td ><button onClick={toggle2} className='orderBtn' value={val.id}>{val.storeid}</button></td>
                <td>{val.storename}</td>       
                <td>{val.TotalOrders}</td>               
              </tr>
              
            );
          })}
        </tbody>
      </Table>
        </div>

        <Modal isOpen={modal} toggle={toggle} className="custom-modal" >
            <ModalHeader>New Store</ModalHeader>
            <div className="modalWrapper">
                <p style={{color:'red'}}>*Fill the details below. All fields are mandatory</p>
                <FloatingLabel className='formInput' onChange={(e)=>{setStoreid(e.target.value)}} controlId="floatingInputGrid" label="Store id">
                    <Form.Control  className='inputSearch'  type="text" placeholder="e.g Naivas-thika-road" />
                </FloatingLabel>
                <FloatingLabel className='formInput' onChange={(e)=>{setStorename(e.target.value)}} controlId="floatingInputGrid" label="Store name">
                    <Form.Control  className='inputSearch'  type="text" placeholder="e.g Naivas Nountain mall" />
                </FloatingLabel>
                <Autocomplete
                    onLoad={autocomplete => (window.autocomplete = autocomplete)}
                    onPlaceChanged={() => onPlaceSelected(window.autocomplete)}
                >
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Store location">
                    <Form.Control onChange={e => setStorelocation(e.target.value)} value={storeLocation} className='inputSearch' type="text" placeholder="Store location" />
                </FloatingLabel>

                </Autocomplete>

                <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label="Store password">
                    <Form.Control  className='inputSearch'  type="password" placeholder="password" />
                </FloatingLabel>
                <FloatingLabel className='formInput' onChange={(e)=>{setConfirmpassword(e.target.value)}} controlId="floatingInputGrid" label="Confirm password">
                    <Form.Control  className='inputSearch'  type="password" placeholder="Confirm password" />
                </FloatingLabel>
                <Button onClick={submitStore} color='info' className='modBtn'>Add store</Button>
            </div>

        </Modal>

        <Modal isOpen={modal2} toggle={toggle2} >
            <ModalHeader>Edit store</ModalHeader>
            <div className="modalWrapper">
                <p>Edit store</p>
                <FloatingLabel className='formInput' onChange={(e)=>{setStoreid(e.target.value)}} controlId="floatingInputGrid" label={storeid}>
                    <Form.Control  className='inputSearch'  type="text" placeholder={storeid} />
                </FloatingLabel>
                <FloatingLabel className='formInput' onChange={(e)=>{setStorename(e.target.value)}} controlId="floatingInputGrid" label={storename}>
                    <Form.Control  className='inputSearch'  type="text" placeholder={storename} />
                </FloatingLabel>
                <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label={password}>
                    <Form.Control  className='inputSearch'  type="password" placeholder={password} />
                </FloatingLabel>
                <div className="modButtons">
                <Button onClick={editStore} color='warning' className='modBtn'>Save changes</Button>
                <Button onClick={toggle3} color='danger' className='modBtn'>Remove store</Button>
                </div>
               
            </div>

        </Modal>

        <Modal isOpen={modal3} toggle={toggle3}>
            <ModalHeader>Remove Store</ModalHeader>
            <div className="modalWrapper">
                <p>Are you sure you want to remove this store?</p>
                <div className="modButtons">
                    <Button onClick={deleteStore} color='success' className='modBtn'>YES</Button>
                    <Button color='danger' className='modBtn' onClick={toggle3}>NO</Button>
                </div>
            </div>

        </Modal>



    </div>
        
    </div>

</div>
</div>
    
  )
}
