import {React, useState,useEffect} from 'react'
import './reports.css'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Table } from 'reactstrap';
import Axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

export default function Reports() {
    const [rider, setRider] = useState('');
    const [store, setStore] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [stores,setStores] = useState([]);
    const [riders, setRiders] = useState([]);
    const [allOrders, setAllorders] = useState([]);
    const [search, setSearch] = useState([])

    useEffect(()=>{
        Axios.get('https://panel.yunshuglobal.com:3051/api/getAllstores').then((res)=>{
            setStores(res.data)
        })
        Axios.get('https://panel.yunshuglobal.com:3051/api/getAllriders').then((res)=>{
            setRiders(res.data)
        })
        Axios.get('https://panel.yunshuglobal.com:3051/api/getAllorders').then((res)=>{
            setAllorders(res.data)
        })

    },[])

    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
       
      };

    useEffect(()=>{
        Axios.get(`https://panel.yunshuglobal.com:3051/api/searchOrder?search=${search}`).then((res)=>{
            setAllorders(res.data)
        })
    },[search])

  return (
    <div className='homeWrapper'>
    <Header />
<div className='homeContainer'>
    <div className="sidebar">
        <Sidebar />
    </div>
    <div className="maincontent">
    <div className='storesWrapper'>
        <h4 className='storesTitle'>Reports</h4>
        <hr />
        <FloatingLabel className='formSearch'  controlId="floatingInputGrid" label='Search order' onChange={(e)=>{setSearch(e.target.value)}}>
                <Form.Control  className='inputSearch'  type="text" placeholder='Search order' />
        </FloatingLabel>
        

        <h6 className='filter'>Filter by</h6>
        <div className="reportsIntro">
        <FloatingLabel controlId="floatingInputGrid" label="Select Store" className="formInput"  >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setStore(e.target.value)}}  >
                <option selected disabled>Select store</option>
                <option value="All">All</option>
                {stores.map((val)=>{
                    return(
                        <option value={val.storename}>{val.storename}</option>
                    )
                })}                  
            </Form.Select>
                    
        </FloatingLabel>
        <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="formInput" >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setRider(e.target.value)}}  >
                <option selected disabled>Select rider</option>    
                <option value="All">All</option>  
                {riders.map((val)=>{
                    return(
                        <option value={val.rideremail}>{val.ridername}</option>
                    )
                })}                
            </Form.Select>
                    
        </FloatingLabel>
        <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='Start perid' onChange={handleDateChange}>
                <Form.Control  className='inputSearch'  type="datetime-local" placeholder='Start period' />
        </FloatingLabel>
        <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='End perid' onChange={handleDateChange2}>
                <Form.Control  className='inputSearch'  type="datetime-local" placeholder='End period' />
        </FloatingLabel>

        </div>

        <div className="reportsBody">
        <div className="storesTable">
            <div className="exportButton">
            <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="downloadBtn"
            table="table-to-xls"
            filename="Albatrox Logistics Report"
            sheet="New Report"
            buttonText="EXPORT"/>

            </div>
        
    <Table id="table-to-xls" className="table">
    <thead>
      <tr>
      <th className='tHeading'>Date</th>
        <th className='tHeading'>#</th>
        <th className='tHeading'>value</th>
        <th className='tHeading'>Customer</th>
        <th className='tHeading'>Phone</th>
        <th className='tHeading'>Location</th>
        <th className='tHeading'>Rider</th>
        <th className='tHeading'>Status</th>
        <th className='tHeading'>Created</th>
        <th className='tHeading'>Assigned</th>
        <th className='tHeading'>Dispatched</th>
        <th className='tHeading'>Arrived</th>
        <th className='tHeading'>Delivered</th>
        <th className='tHeading'>Cancelled</th>
        <th className='tHeading'>Returned</th>
        <th className='tHeading'>Distance</th>
        <th className='tHeading'>Amount</th>

      </tr>
    </thead>
    <tbody>
      {allOrders.map((val, index) => {
        let assigned = ''
        let dispatched = ''
        let arrived = ''
        let delivery = ''
        let cancel = ''
        let returned = ''

        const postDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(val.postTime);
        const postTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.postTime);

        if(val.assignedTime !== null){
            assigned =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.assignedTime);
        }
        if(val.dispatchedTime !== null){
            dispatched =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.dispatchedTime);
        }
        if(val.arrivalTime !== null){
            arrived =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.arrivalTime);
        }
        if(val.deliveryTime !== null){
            delivery =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.deliveryTime);
        }
        if(val.cancelTime !== null){
            cancel =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.cancelTime);
        }
        if(val.returnTime !== null){
            returned =  new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.returnedTime);
        }

        if(val.postTime > start && val.postTime < end){
            if(store == 'All'){
                if(rider == 'All'){
                    return (
           
                        <tr key={index} className='roow'>
                         <td>{postDate}</td> 
                         <td >{val.orderNumber}</td>
                          <td>{val.orderValue}</td>       
                          <td>{val.customerName}</td> 
                          <td>{val.customerPhone}</td>   
                          <td>{val.location}</td>  
                          <td>{val.rider}</td> 
                          <td>{val.status}</td>   
                          <td>{postTime}</td> 
                          <td>{assigned}</td> 
                          <td>{dispatched}</td> 
                          <td>{arrived}</td> 
                          <td>{delivery}</td>   
                          <td>{cancel}</td>
                          <td>{returned}</td>
                          <td>{val.distance}km</td>
                          <td>Kes.{val.amount}</td>
                        </tr>
                        
                      );

                }
                else if(rider == val.rider){
                    return (
           
                        <tr key={index} className='roow'>
                         <td>{postDate}</td> 
                         <td >{val.orderNumber}</td>
                          <td>{val.orderValue}</td>       
                          <td>{val.customerName}</td> 
                          <td>{val.customerPhone}</td>   
                          <td>{val.location}</td>  
                          <td>{val.rider}</td> 
                          <td>{val.status}</td>   
                          <td>{postTime}</td> 
                          <td>{assigned}</td> 
                          <td>{dispatched}</td> 
                          <td>{arrived}</td> 
                          <td>{delivery}</td>   
                          <td>{cancel}</td>
                          <td>{returned}</td>
                          <td>{val.distance}km</td>
                          <td>Kes.{val.amount}</td>
                        </tr>
                        
                      );
                }
            }
            else if(store == val.store){
                if(rider == 'All'){
                    return (
           
                        <tr key={index} className='roow'>
                         <td>{postDate}</td> 
                         <td >{val.orderNumber}</td>
                          <td>{val.orderValue}</td>       
                          <td>{val.customerName}</td> 
                          <td>{val.customerPhone}</td>   
                          <td>{val.location}</td>  
                          <td>{val.rider}</td> 
                          <td>{val.status}</td>   
                          <td>{postTime}</td> 
                          <td>{assigned}</td> 
                          <td>{dispatched}</td> 
                          <td>{arrived}</td> 
                          <td>{delivery}</td>   
                          <td>{cancel}</td>
                          <td>{returned}</td>
                          <td>{val.distance}km</td>
                          <td>kes.{val.amount}</td>
                        </tr>
                        
                      );
                }
                else if(rider == val.rider){
                    return (
           
                        <tr key={index} className='roow'>
                         <td>{postDate}</td> 
                         <td >{val.orderNumber}</td>
                          <td>{val.orderValue}</td>       
                          <td>{val.customerName}</td> 
                          <td>{val.customerPhone}</td>   
                          <td>{val.location}</td>  
                          <td>{val.rider}</td> 
                          <td>{val.status}</td>   
                          <td>{postTime}</td> 
                          <td>{assigned}</td> 
                          <td>{dispatched}</td> 
                          <td>{arrived}</td> 
                          <td>{delivery}</td>   
                          <td>{cancel}</td>
                          <td>{returned}</td>
                          <td>{val.distance}km</td>
                          <td>Kes.{val.amount}</td>
                        </tr>
                        
                      );
                }
            }

        }
        else{
            return (
           
                <tr key={index} className='roow'>
                 <td>{postDate}</td> 
                 <td >{val.orderNumber}</td>
                  <td>{val.orderValue}</td>       
                  <td>{val.customerName}</td> 
                  <td>{val.customerPhone}</td>   
                  <td>{val.location}</td>  
                  <td>{val.rider}</td> 
                  <td>{val.status}</td>   
                  <td>{postTime}</td> 
                  <td>{assigned}</td> 
                  <td>{dispatched}</td> 
                  <td>{arrived}</td> 
                  <td>{delivery}</td>   
                  <td>{cancel}</td>
                  <td>{returned}</td>
                  <td>{val.distance}km</td>
                  <td>Kes.{val.amount}</td>
                </tr>
                
              );

        }
      
        
      })}
    </tbody>
  </Table>
    </div>

        </div>

    </div>
        
    </div>

</div>
</div>
    
  )
}
