import {React, useState, useEffect }from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Button} from 'reactstrap'
import Form from 'react-bootstrap/Form';
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';


export default function Forgot() {

    const[email, setEmail] = useState("");
    const[mail2,setMail2] = useState('')
    const[password, setPassword] = useState("")
    const navigate = useNavigate();
    const [loading, setLoading] = useState('Send Link')

    const login = ()=>{
        if(email != ''){
            toast.error('Follow the link sent to your email')
        }
        else{
            toast.error('Enter your email address')
        }
    }
  return (
    <div className='loginWrapper'>
        <ToastContainer />
        <div className="loginContainer">
            <img src="/assets/yunshulogo.jpg" alt="logo" className='logo' />
            <h5>Yunshu Africa Limited</h5>
        

        <FloatingLabel className='formInput' onChange={(e)=>{setEmail(e.target.value)}} controlId="floatingInputGrid" label="User email">
              <Form.Control  className='inputSearch'  type="text" placeholder="User email" />
          </FloatingLabel>
        
          <div className="signinBtn">
            <Button onClick={login} className='loginBtn' color='success'>{loading}</Button>
            <a className='forgot' href="http://localhost:3000">Back to Login</a>
            
          </div>
          
            
        </div>

    </div>
  )
}
